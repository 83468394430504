<template>
    <div>
        <div class="card-toolbar mb-5">
            <button v-if="$can('brands.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('brands.new_brand') }}
            </button>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_name">{{$t('name')}}</label>
                            <input v-model="filters.name" type="text" id="f_name" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="name">{{$t('status')}}</label>
                            <select name="" id="is_active" v-model="filters.is_active" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{$t('search')}}</span>
                                </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{$t('reset_search')}}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <div class="d-flex justify-content-end mb-5">
                    <custom-export-data v-if="$can('brands.export_data')" :data-list="dataList" :file-name="fileName" :fields="json_fields"></custom-export-data>
                </div>
                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
                    <template slot="status" slot-scope="props">
                        <b-form-checkbox v-if="$can('brands.change_status')"
                            size="lg" @change="changeStatus(props.row.id, props.row.is_active)" 
                            v-model="props.row.is_active" 
                            :name="'check-button'+props.row.id" 
                            switch :key="props.row.id">
                        </b-form-checkbox>
                        <b-form-checkbox v-else
                            size="lg" :disabled="true"
                            v-model="props.row.is_active"
                            :name="'check-button'+props.row.id"
                            switch :key="props.row.id">
                        </b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small v-if="$can('brands.update')" class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" @click="showModal(props.row)">mdi-pencil</v-icon>
                        <v-icon small v-if="$can('brands.delete')" class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" @click="deletebrand(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>
                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

        <b-modal ref="modal" hide-footer :title="$t('brands.brand')">
          <div class="row">
            <div class="col-md-12 mb-5">
              <label for="name">
                {{ $t('brands.name') }}<span class="text-danger">*</span>
              </label>
              <input v-model="brand.name" type="text" id="name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''">
              <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.name[0] }}
                </span>

            </div>
            <div class="col-md-6 mb-5">
              <label>{{$t('upload_logo')}}</label>
              <upload-file
                  @file="listenerAttachment"
                  :inner-id="'image'"
                  :placeholder="$t('upload_logo')"
                  :upload="dir_upload"
                  :start-link="'base'"
                  v-bind:valueprop="{name:'image',path:image_url}" v-if="reloadUploadAttachment">
              </upload-file>
            </div>
            <div class="col-md-6 mb-5">
                <div class="form-group">
                  <label>{{$t('status')}}:</label>
                  <b-form-checkbox size="lg" v-model="brand.is_active" name="check-button" switch></b-form-checkbox>
                </div>
              </div>
            <div class="col-md-12 mb-5">
              <label for="notes">{{$t('brands.notes')}}</label>
              <textarea v-model="brand.notes" type="text" class="form-control" id="notes" style="height: 63px;" :placeholder="$t('notes')"></textarea>
            </div>
          </div>

            <div class="d-flex justify-content-end">
                <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>
                <b-button class="mt-2 mr-1" variant="primary" @click="save">{{$t('save')}}</b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index",
        components: {},
        data() {
            return {
                dir_upload: 'brands',
                brand: {
                    name: '',
                    notes : '',
                    image:null,
                    is_active: true,
                },
                showAdvancedSearch: false,
                filter: {
                    sortBy: 'id',
                },
                filters: {
                    name: '',
                    is_active: '',
                },
                columns: ['name', 'created_at','user','status', 'actions'],
                validation: null,
                image_url: null,
                reloadUploadAttachment: true,
                mainRoute:'settings/brands',
                routeChangeStatus : 'settings/brand/change-status',
                isEditing: false,
                id: null,
                dataList : [],
                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
            }
        },
        watch: {
            // 'brand.name': function (val) {
            //     if (val){
            //         this.brand.name = val.replace(/[\d]/g, "");
            //     }
            // }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('name')] = 'name';
                fields[this.$t('created_at')] = 'created_at';
                fields[this.$t('user')] = 'user';
                fields[this.$t('status')] = 'is_active';
                return fields;
            },
            fileName : function () {
                return this.$t('brands.brands');
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter')+' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading')+"...",
                    },
                    headings: {
                        name: that.$t('name'),
                        created_at: that.$t('created_at'),
                        user: that.$t('user'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',
                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }
            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.brands_management")}]);
            // let that = this;
            // this.$root.$on('bv::modal::hidden', () => {
            //     that.afterSavebrand();
            // });
        },
        methods: {
            changeStatus(id, status) {
                ApiService.patch(this.routeChangeStatus + '/' + id, {
                    is_active: (status ? 1 : 0),
                }).then((response) => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch((error) => {
                    this.$errorAlert(error);
                });
            },

            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = '';
                this.filters.is_active = '';
                this.filters.notes = '';

                this.$refs.table.refresh();
            },
            save() {
                if (this.isEditing) {
                    this.updatebrand();
                } else {
                    this.savebrand();
                }
            },
            getFetch() {
                this.$refs.table.refresh();
            },
            actionDelete(brand){
                ApiService.delete(`${this.mainRoute}/${brand.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error)
                })
            },
            deletebrand(brand) {
                this.$confirmAlert('', this.actionDelete, brand);
            },

            showModal(data) {
                if (!data.id) {
                    this.$refs['modal'].show()
                }else{
                    this.isEditing =true;
                    this.id = data.id;
                    this.getData(data.id);
                    this.$refs['modal'].show();
                }
            },
            hideModal() {
                this.$refs['modal'].hide()
            },
            toggleModal() {
                this.$refs['modal'].toggle('#toggle-btn')
            },
            savebrand() {
                ApiService.post(this.mainRoute, this.brand).then((response) => {
                    this.$successAlert(response.data.message);
                    this.hideModal();
                    this.afterSavebrand();
                }).catch((errors) => {
                    this.$errorAlert(errors);
                    this.validation = errors.response ? errors.response.data.errors : null;
                });
            },
            updatebrand() {
                ApiService.put(`${this.mainRoute}/${this.id}`, {
                    id:this.id,
                    ...this.brand,
                }).then((response) => {
                    this.$successAlert(response.data.message);
                    this.validation = null;
                    this.hideModal();
                    this.afterSavebrand();
                })
                .catch((errors) => {
                    this.$errorAlert(errors);
                    this.validation = errors.response ? errors.response.data.errors : null;
                });
            },
            getData(id) {
                this.reloadUploadAttachment = false;
                ApiService.get(`${this.mainRoute}/${id}`).then((response) => {
                    this.isEditing = true;
                    this.image_url = response.data.data.image_url;
                    this.brand.image= response.data.data.image;
                    this.brand.name = response.data.data.name;
                    this.brand.is_active = response.data.data.is_active;
                    this.brand.notes = response.data.data.notes;
                    this.reloadUploadAttachment = true;
                });

            },

            listenerAttachment(event) {
                if (event) {
                    this.image_url = event.pathDB;
                    this.brand.image = event.name;
                }
            },

            afterSavebrand() {
                this.reloadUploadAttachment = false;
                this.brand = {
                    name: '',
                    notes: '',
                    image:null,
                    is_active: true,
                };
                this.image_url = null;
                this.reloadUploadAttachment = true;
                this.validation = null;
                // this.hideModal();
                this.getFetch();
            }
        },
    };
</script>
